import React from 'react';
import { Markup } from 'interweave';
import { remark } from 'remark';
import remarkHTML from 'remark-html';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import styled from 'styled-components';

const colors = [
  'var(--dynamic-blue)',
  'var(--dynamic-purple)',
  'var(--dynamic-red)',
  'var(--dynamic-yellow)',
  'var(--dynamic-purple)'
];

const WhyChoose = ({ data }) => {
  const { title, summary, highlights } = data;
  const toHTML = (value) => remark().use(remarkHTML).processSync(value).toString();
  return (
    <Container>
      <h3>{title}</h3>
      <Markup content={toHTML(summary)} />
      <Highlights>
        {highlights.map((highlight, index) => (
          <div key={highlight.title}>
            <IconContainer color={colors[index]}>
              <GatsbyImage image={getImage(highlight.whyChooseIcon)} alt={highlight.title} />
            </IconContainer>
            <h4>{highlight.title}</h4>
            <p>{highlight.description}</p>
          </div>
        ))}
      </Highlights>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 1.5rem;
  h3,
  h4 {
    font-family: adelle, serif;
    font-weight: 600;
  }
  h3 {
    color: var(--dynamic-purple);
    font-size: 1.9rem;
    margin-bottom: 0.5em;
    max-width: 440px;
    text-align: left;
  }
  h4 {
    text-align: center;
    max-width: 158px;
  }
  p {
    margin-bottom: 1.5rem;
  }

  span {
    font-family: 'proxima-nova', sans-serif;
  }

  strong {
    font-weight: 600;
  }

  ol,
  ul {
    li {
      margin-left: 1rem;
      margin-bottom: 0.5rem;
    }
    margin-bottom: 1.5rem;
  }
  @media screen and (min-width: 1143px) {
    h3 {
      font-size: 2rem;
    }
  }
`;

const Highlights = styled.div`
  display: flex;
  flex-direction: column;
  > div {
    align-items: center;
    display: flex;
    flex-direction: column;
    gap: 0.75rem;
    padding: 1rem 2rem 2rem 2rem;
    position: relative;
    &:not(:last-of-type) {
      &::before {
        content: '';
        height: 1px;
        background: var(--dynamic-blue);
        width: 160px;
        position: absolute;
        right: 50%;
        bottom: 0;
        opacity: 0.2;
        transform: translateX(50%);
      }
    }
    p {
      font-size: 0.75rem;
      font-weight: 500;
      text-align: center;
      max-width: 240px;
      margin: 0 auto;
      margin-top: auto;
    }
  }
  @media (min-width: 768px) {
    font-size: 1rem;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    > div {
      &:nth-of-type(odd) {
        padding: 1rem 2rem 2rem 0;
      }
      &:nth-of-type(even) {
        padding: 1rem 0 2rem 2rem;
      }
      &:first-of-type {
        &::after {
          content: '';
          height: 160px;
          background: var(--dynamic-blue);
          width: 1px;
          position: absolute;
          right: 0;
          bottom: 0.75rem;
          opacity: 0.2;
        }
        &::before {
          content: '';
          height: 1px;
          background: var(--dynamic-blue);
          width: 160px;
          position: absolute;
          right: 0.75rem;
          bottom: 0;
          opacity: 0.2;
          transform: unset;
        }
      }
      &:nth-of-type(2) {
        &::before {
          content: '';
          height: 1px;
          background: var(--dynamic-blue);
          width: 160px;
          position: absolute;
          left: 0.75rem;
          bottom: 0;
          opacity: 0.2;
          transform: unset;
        }
      }
      &:nth-of-type(3) {
        &::before {
          content: '';
          height: 0;
          background: var(--dynamic-blue);
          width: 0;
          position: absolute;
          left: 0.75rem;
        }
      }
      &:last-of-type {
        grid-column: ${({ centerDiv }) => (centerDiv ? 'span 2' : 'span 1')};
      }
      &:nth-of-type(4) {
        &::before {
          content: '';
          height: 160px;
          background: var(--dynamic-blue);
          width: 1px;
          position: absolute;
          left: 0rem;
          top: 0.75rem;
          opacity: 0.2;
        }
      }
    }
  }
`;

const IconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 64px;
  width: 64px;
  background-color: ${({ color }) => color};
  border-radius: 10px;
  position: relative;
  margin: 0 auto;
  z-index: 1;
  .gatsby-image-wrapper {
    z-index: 2;
  }
  &:before {
    content: '';
    display: block;
    height: 100%;
    width: 100%;
    background-color: ${({ color }) => color};
    opacity: 0.2;
    border-radius: 10px;
    transform: rotate(45deg);
    position: absolute;
    top: 0;
    left: 0;
    z-index: 0;
  }
`;

export default WhyChoose;
