import * as React from 'react';
function SvgAccordianButton(props) {
  return (
    <svg width={48} height={48} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g filter="url(#accordian-button_svg__filter0_d_1147_3241)">
        <rect x={4} width={40} height={40} rx={20} fill="#FFC700" />
        <path
          d="M26.187 21.825V28h-3.374v-6.175H17v-2.869h5.813V13h3.374v5.956H32v2.87h-5.813z"
          fill="#4F4F4F"
        />
      </g>
      <defs>
        <filter
          id="accordian-button_svg__filter0_d_1147_3241"
          x={0}
          y={0}
          width={48}
          height={48}
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB">
          <feFlood floodOpacity={0} result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy={4} />
          <feGaussianBlur stdDeviation={2} />
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0" />
          <feBlend mode="multiply" in2="BackgroundImageFix" result="effect1_dropShadow_1147_3241" />
          <feBlend in="SourceGraphic" in2="effect1_dropShadow_1147_3241" result="shape" />
        </filter>
      </defs>
    </svg>
  );
}
export default SvgAccordianButton;
