const sortTeamByGridOrder = (elements, gridOrders) => {
    const elementsGridOrder =
      gridOrders.childMarkdownRemark.frontmatter[
        Object.keys(gridOrders.childMarkdownRemark.frontmatter)[0]
      ];
  
    const sortedElements = elementsGridOrder
      .map(
        ({ name }) =>
          elements.edges.filter(({ node }) => node.childMarkdownRemark.frontmatter.name === name)[0]
      )
      .filter((card) => card !== undefined && card.node.childMarkdownRemark.frontmatter.name);
  
    const notSortedElements = elements.edges
      .filter(
        ({ node }) =>
          !elementsGridOrder
            .map(({ name }) => name)
            .includes(node.childMarkdownRemark.frontmatter.name)
      )
      .filter((card) => card !== undefined && card.node.childMarkdownRemark.frontmatter.name);
  
    return [...sortedElements, ...notSortedElements];
  };
  
  export default sortTeamByGridOrder;