import React, { useState } from 'react';
import styled, { css, keyframes } from 'styled-components';
import { StaticQuery, graphql, Link } from 'gatsby';
import { Button } from 'components';
import SmallRightArrow from 'images/shared/misc/SmallRightArrow';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';

const services = [
  {
    title: 'Team Building',
    ariaLabel: 'team building',
    altText: 'Team cheering',
    link: '/team-building',
    color: '#4DB1E8'
  },
  {
    title: 'Team Training',
    ariaLabel: 'team training',
    altText: 'Person placing sticky notes on glass while team watches',
    link: '/team-training',
    color: '#ED5145'
  },
  {
    title: 'Event Management',
    ariaLabel: 'event management',
    altText: 'People seated and watching an event',
    link: '/event-management',
    color: '#712ca5'
  }
];

const ChooseExperience = ({ viewportWidth }) => {
  const [hoveredService, setHoveredService] = useState(null);

  return (
    <Container>
      <h4>What we do</h4>
      <StaticQuery
        query={graphql`
          {
            youngPeopleHappyAndCheering: file(
              relativePath: {
                eq: "home/choose_your_experience/young_people_happy_and_cheering.jpg"
              }
            ) {
              name
              childImageSharp {
                gatsbyImageData(
                  width: 340
                  height: 340
                  quality: 50
                  pngOptions: { compressionSpeed: 10 }
                  placeholder: TRACED_SVG
                  formats: [AUTO, WEBP]
                )
              }
            }
            peopleSittingInCinema: file(
              relativePath: { eq: "home/choose_your_experience/people_sitting_in_cinema.jpg" }
            ) {
              name
              childImageSharp {
                gatsbyImageData(
                  width: 340
                  height: 340
                  quality: 50
                  pngOptions: { compressionSpeed: 10 }
                  placeholder: TRACED_SVG
                  formats: [AUTO, WEBP]
                )
              }
            }
            peopleTeamTraining: file(
              relativePath: { eq: "home/choose_your_experience/team_training.jpg" }
            ) {
              name
              childImageSharp {
                gatsbyImageData(
                  width: 340
                  height: 340
                  quality: 50
                  pngOptions: { compressionSpeed: 10 }
                  placeholder: TRACED_SVG
                  formats: [AUTO, WEBP]
                )
              }
            }
          }
        `}
        render={({ youngPeopleHappyAndCheering, peopleSittingInCinema, peopleTeamTraining }) => {
          return (
            <ServiceWrapper>
              {services.map((service, index) => {
                const image =
                  index === 0
                    ? youngPeopleHappyAndCheering
                    : index === 1
                    ? peopleTeamTraining
                    : peopleSittingInCinema;
                return (
                  <Service
                    hovered={hoveredService === service.ariaLabel || hoveredService === ''}
                    color={service.color}
                    onMouseEnter={() => setHoveredService(service.ariaLabel)}
                    onMouseLeave={() => setHoveredService('')}>
                    <Link to={service.link}>
                      <ServiceImg image={getImage(image)} />
                      {viewportWidth < 600 ? (
                        <Button text={service.title} ariaLabel={service.ariaLabel} width="240px">
                          <StyledSmallRightArrow />
                        </Button>
                      ) : (
                        <ServiceName color={service.color}>{service.title}</ServiceName>
                      )}
                    </Link>
                  </Service>
                );
              })}
            </ServiceWrapper>
          );
        }}
      />
    </Container>
  );
};

const StyledSmallRightArrow = styled(SmallRightArrow)`
  margin-left: 5px;

  path {
    stroke: #fff;
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin: 3rem auto;
  gap: 2rem;
  max-width: 1264px;
  h4 {
    text-align: center;
    font-size: 2rem;
    width: 100%;
    z-index: 1;
  }
  @media screen and (min-width: 1024px) {
    margin: 6rem auto 12rem auto;
    gap: 3rem;
  }
  @media screen and (min-width: 1600px) {
    margin: 0 auto 8rem auto;
  }
`;

const ServiceWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  max-width: 1100px;
  gap: 1rem;
  width: 100%;

  @media screen and (min-width: 600px) {
    flex-direction: row;
    flex-flow: row wrap;
    row-gap: 3rem;
    justify-content: space-between;
  }

  @media screen and (min-width: 868px) {
    flex-flow: unset;
  }

  a {
    text-decoration: none;
  }
`;

const ServiceImg = styled(GatsbyImage)`
  border-radius: 5px;
  box-shadow: 0.438em 0.438em 0.375em rgba(189, 189, 189, 0.3);
  transition: transform 0.3s ease-in-out;
  @media screen and (min-width: 600px) {
    border-radius: 10px;
  }
`;

const Service = styled.div`
  cursor: pointer;
  transition: transform 0.3s ease-in-out;
  margin: 0 auto;

  @media screen and (min-width: 600px) {
    max-width: 47%;
    width: 100%;
  }

  @media screen and (min-width: 864px) {
    max-width: 49.09%;
  }

  button {
    margin: 0 auto;
    position: relative;
    bottom: 22px;

    span {
      display: flex;
      margin-left: 4px;
      margin-bottom: 1px;
      flex-direction: row-reverse;
    }
  }
`;

const ServiceName = styled.div`
  background-color: ${({ color }) => color};
  border-radius: 32px;
  color: #ffffff;
  font-family: 'proxima-nova', sans-serif;
  font-size: 1.25rem;
  font-weight: bold;
  text-align: center;
  padding: 0.75rem 1.25rem;
  margin: 2rem auto 0 auto;
  width: 250px;
`;

export default ChooseExperience;
