import React from 'react';
import styled from 'styled-components';
import { GatsbyImage } from 'gatsby-plugin-image';

const colors = [
  'var(--dynamic-blue)',
  'var(--dynamic-purple)',
  'var(--dynamic-red)',
  'var(--dynamic-blue)',
  'var(--dynamic-yellow)',
  'var(--dynamic-purple)'
];

const HighlightedPoints = ({ data }) => {
  return (
    <Container>
      <Introduction>
        <h2>{data.title}</h2>
        <p>{data.summary}</p>
      </Introduction>
      <Points>
        {data.highlights.map((point, index) => (
          <Point key={point.title}>
            <IconContainer color={colors[index]}>
              <GatsbyImage image={point.icon.childImageSharp.gatsbyImageData} alt={point.title} />
            </IconContainer>
            <PointText>
              <h5>{point.title}</h5>
              <p>{point.description}</p>
            </PointText>
          </Point>
        ))}
      </Points>
    </Container>
  );
};

const Container = styled.div`
  max-width: 1145px;
  margin: 6rem auto;
  padding: 0 1.5rem;
  @media screen and (min-width: 1200px) {
    margin: 3rem auto 0 auto;
  }
`;

const Introduction = styled.div`
  max-width: 914px;
  margin: 3rem auto 0 auto;
  padding: 0 1.5rem;

  h2 {
    color: var(--dynamic-purple);
    font-family: adelle, serif;
    font-size: 1.9rem;
    font-weight: 600;
    text-align: center;
    margin-bottom: 2rem;

    @media screen and (min-width: 1143px) {
      font-size: 2rem;
    }
  }

  p {
    color: var(--dynamic-dark-grey);
    font-family: 'proxima-nova', sans-serif;
    font-size: 1.125rem;
    font-weight: 400;
    font-style: normal;
    letter-spacing: 0.01em;
    margin-bottom: 1.75rem;
    text-align: center;
    a {
      color: var(--dynamic-blue);
      text-decoration: underline;
      font-weight: 600;
    }
  }
  @media screen and (min-width: 1200px) {
    font-size: 1.3rem;
    margin: 6rem auto 0 auto;
    padding: 0;
  }
`;

const Points = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1.5rem;
  @media screen and (min-width: 1200px) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 2rem;
  }
`;

const Point = styled.div`
  display: grid;
  grid-template-columns: 90px 1fr;
  align-items: center;
  gap: 1rem;
  h5 {
    font-family: adelle, serif;
    font-size: 1rem;
    font-weight: 600;
    margin: 0;
  }
  p {
    font-family: 'proxima-nova', sans-serif;
    font-size: 1rem;
    font-weight: 400;
    color: var(--dynamic-dark-grey);
    margin: 0;
  }
`;

const PointText = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
`;

const IconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 64px;
  width: 64px;
  background-color: ${({ color }) => color};
  border-radius: 10px;
  position: relative;
  margin: 0 auto;
  z-index: 1;
  .gatsby-image-wrapper {
    z-index: 2;
  }
  &:before {
    content: '';
    display: block;
    height: 100%;
    width: 100%;
    background-color: ${({ color }) => color};
    opacity: 0.2;
    border-radius: 10px;
    transform: rotate(45deg);
    position: absolute;
    top: 0;
    left: 0;
    z-index: 0;
  }
`;

export default HighlightedPoints;
