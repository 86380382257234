import * as React from 'react';
function SvgDuration(props) {
  return (
    <svg width={64} height={64} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M32 56.96c12.37 0 22.4-10.029 22.4-22.4 0-12.371-10.03-22.4-22.4-22.4-12.372 0-22.4 10.029-22.4 22.4 0 12.371 10.028 22.4 22.4 22.4zM32 34.56l9.28-9.28"
        stroke="#fff"
        strokeWidth={2}
        strokeMiterlimit={10}
      />
      <path
        d="M32 36.16a1.6 1.6 0 100-3.2 1.6 1.6 0 000 3.2zM32 17.28v17.28M32 7.04v4.8M28 7.04h8"
        stroke="#fff"
        strokeWidth={2}
        strokeMiterlimit={10}
      />
    </svg>
  );
}
export default SvgDuration;
