import * as React from 'react';
function SvgApproach(props) {
  return (
    <svg width={64} height={64} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M44.802 28.16c0-7.52-6.56-13.6-14.24-12.64-5.76.64-10.4 5.28-11.2 11.04-.48 4 .8 7.52 3.2 10.24 2.88 3.2 4.8 7.2 4.8 11.52v1.28h9.6v-.96c0-4.32 1.44-8.48 4.48-11.52 1.92-2.4 3.36-5.44 3.36-8.96zM32.002 49.6V31.68M27.201 53.28c0 2.72 2.08 4.8 4.8 4.8 2.72 0 4.8-2.08 4.8-4.8M43.041 14.88l3.2-3.2M32.002 10.4V5.92M17.762 11.68l3.2 3.2M53.763 25.92h-4.48M14.722 25.92h-4.48M27.201 31.68h9.6"
        stroke="#fff"
        strokeWidth={2}
        strokeMiterlimit={10}
      />
    </svg>
  );
}
export default SvgApproach;
