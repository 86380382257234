import React from 'react';
import styled from 'styled-components';
import { Markup } from 'interweave';
import { remark } from 'remark';
import remarkHtml from 'remark-html';
import { Button } from 'components';
import { scrollToAnchor } from 'utils';
import CheckmarkIcon from 'images/shared/misc/check.svg';

const WhyChooseThisActivity = ({ whyChooseThisActivity }) => {
  const { title, summary, highlights, includeContactUsCTA } = whyChooseThisActivity;
  const toHTML = (value) => remark().use(remarkHtml).processSync(value).toString();
  return (
    <Wrapper>
      <h2>{title}</h2>
      {summary && <p>{summary}</p>}
      <Highlights>
        {highlights &&
          highlights.map((highlight, index) => (
            <Highlight key={index}>
              <IconContainer>
                <img src={CheckmarkIcon} alt="Checkmark" />
              </IconContainer>
              <TextContainer>
                <Markup content={toHTML(highlight.highlight)} />
              </TextContainer>
            </Highlight>
          ))}
      </Highlights>
      {includeContactUsCTA && (
        <ContactUsCTA>
          <p>
            <strong>Not Sure What's Right for Your Team?</strong>
            <br />
            Planning an event but not sure where to start? Tell us a little about your group, and
            we'll recommend the perfect experience—stress-free and tailored to your team's needs.
          </p>
          <Button
            handleClick={() => {
              scrollToAnchor('contact-us', {
                offset: -91
              });
            }}
            buttonColor="--dynamic-purple"
            buttonHoverColor="--dynamic-red"
            fontColor="--dynamic-white"
            boxShadow="0px 4px 4px rgba(0, 0, 0, 0.15)"
            text="Contact Us"
          />
        </ContactUsCTA>
      )}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;
  h2 {
    color: var(--dynamic-purple);
    font-family: adelle, serif;
    font-size: 2rem;
    font-weight: 600;
    text-align: left;
  }
  @media (min-width: 768px) {
    gap: 3rem;
  }
`;

const Highlights = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
`;

const Highlight = styled.div`
  display: grid;
  grid-template-columns: 32px 1fr;
  align-items: center;
  gap: 1rem;
`;

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  text-align: left;
  gap: 0.125rem;
  p {
    font-size: 1rem;
    font-weight: 400;
    font-family: 'proxima-nova', sans-serif;
    margin: 0;
    strong {
      font-family: adelle, serif;
    }
  }
`;

const IconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 32px;
  width: 32px;
  background-color: var(--dynamic-purple);
  border-radius: 10px;
  position: relative;
  margin: 0 auto;
  z-index: 1;
  img {
    z-index: 2;
  }
  &:before {
    content: '';
    display: block;
    height: 100%;
    width: 100%;
    background-color: var(--dynamic-purple);
    opacity: 0.2;
    border-radius: 10px;
    transform: rotate(45deg);
    position: absolute;
    top: 0;
    left: 0;
    z-index: 0;
  }
`;

const ContactUsCTA = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  p {
    font-size: 1rem;
    font-weight: 400;
    font-family: 'proxima-nova', sans-serif;
    margin: 0;
    strong {
      font-family: adelle, serif;
    }
  }
`;

export default WhyChooseThisActivity;
