import React from 'react';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { scrollToAnchor } from 'utils';
import styled from 'styled-components';
import Button from 'components/Shared/Button';
import BluePurplePointsSVG from 'images/event_management/blue_purple_points.svg';
import BlueArrowSVG from 'images/shared/misc/blue_arrow_purple_circle.svg';
import RedCircleSVG from 'images/event_management/red_circle.svg';

const HighlightBanner = ({
  thumbnail,
  action,
  bannerTitle,
  bannerSubtitle,
  bannerSummary,
  bannerButtonText,
  linkToNavigateTo,
  openLinkInNewTab,
  viewportWidth
}) => {
  const image = getImage(thumbnail);

  const handleClick = (e) => {
    e.preventDefault();
    if (action === 'link') {
      window.open(linkToNavigateTo, openLinkInNewTab ? '_blank' : '_self');
    }
    if (action === 'contactUs') {
      scrollToAnchor('contact-us', {
        offset:
          viewportWidth < 360 ? -60 : viewportWidth < 375 ? -66 : viewportWidth < 967 ? -72 : -91
      });
    }
    if (action === 'openChat') {
      window.open(
        'https://go.crisp.chat/chat/embed/?website_id=00b69e53-4c51-42e2-a625-f876a3efec48',
        '_blank'
      );
    }
  };

  return (
    <Container>
      <BluePurplePoints src={BluePurplePointsSVG} />
      <BlueArrow src={BlueArrowSVG} />
      <RedCircle src={RedCircleSVG} />
      <StyledDiv
        role="button"
        onClick={handleClick}
        className={action === 'openEventManagementTrendsModal' && 'event-management-banner-ec'}>
        <BannerContainer>
          <GatsbyImage image={image} alt={action} />
          <TextContainer>
            <div style={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
              {bannerSubtitle && <span>{bannerSubtitle}</span>}
              {bannerTitle && <h4>{bannerTitle}</h4>}
              {bannerSummary && <p>{bannerSummary}</p>}
              {bannerButtonText && (
                <Button
                  handleClick={handleClick}
                  buttonColor="--dynamic-blue"
                  buttonHoverColor="--dynamic-red"
                  boxShadow="0px 4px 4px rgba(0, 0, 0, 0.15)"
                  text={bannerButtonText}
                />
              )}
            </div>
          </TextContainer>
        </BannerContainer>
      </StyledDiv>
    </Container>
  );
};

const Container = styled.div`
  cursor: pointer;
  width: 100%;
  height: 100%;
  max-width: 1145px;
  margin: 3rem auto;
  padding: 0 1.5rem;
  position: relative;
  .gatsby-image-wrapper {
    position: relative;
    padding: 1rem 2rem;
    z-index: 2;
  }
  @media (min-width: 1024px) {
    margin: 6rem auto;
  }
`;

const StyledDiv = styled.div`
  cursor: pointer;
`;

const BluePurplePoints = styled.img`
  left: -54px;
  position: absolute;
  bottom: -50px;
  display: none;
  height: 178px;
  width: auto;
  z-index: 1;
  @media (min-width: 1024px) {
    display: block;
  }
`;

const BlueArrow = styled.img`
  position: absolute;
  height: 190px;
  display: none;
  width: auto;
  top: -80px;
  right: -10px;
  z-index: 1;
  @media (min-width: 1024px) {
    display: block;
  }
`;

const RedCircle = styled.img`
  position: absolute;
  display: none;
  height: 106px;
  width: auto;
  z-index: 3;
  @media (min-width: 1024px) {
    bottom: unset;
    right: -20px;
    display: block;
    top: -50px;
  }
`;

const BannerContainer = styled.div`
  background-color: var(--dynamic-yellow);
  border-radius: 10px;
  color: white;
  display: grid;
  grid-template-rows: 152px auto;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  position: relative;
  z-index: 2;
  .gatsby-image-wrapper {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
  @media (min-width: 1024px) {
    grid-template-rows: 1fr;
    grid-template-columns: 444px 1fr;
  }
`;

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 1rem;
  padding: 1.5rem 1.25rem;
  span {
    font-family: 'proxima-nova', sans-serif;
    font-size: 1rem;
    font-weight: 600;
    line-height: 1.2;
    max-width: 460px;
  }
  h4 {
    font-family: adelle, serif;
    font-size: 1.5rem;
    font-weight: 600;
    line-height: 1.2;
    max-width: 460px;
  }
  p {
    font-family: 'proxima-nova', sans-serif;
    font-size: 1rem;
    font-weight: 400;
    font-style: normal;
    letter-spacing: 0.01em;
    max-width: 550px;
    strong {
      font-weight: 600;
    }
  }
  @media (min-width: 1024px) {
    flex-direction: row;
    align-items: flex-end;
    padding: 2rem 2rem 1.5rem 2rem;
    h4 {
      font-size: 1.825rem;
    }
  }
`;

export default HighlightBanner;
