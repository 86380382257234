import React, { useState } from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import { Markup } from 'interweave';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import Modal from 'react-modal';
import CloseModalWhite from 'images/shared/misc/close_modal_white.svg';

const customModalStyles = (viewportWidth) => {
  return {
    overlay: {
      zIndex: viewportWidth < 780 ? 10000000 : 6
    },
    content: {
      border: 'none',
      borderRadius: '4px',
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      padding: 0,
      overflow: 'hidden',
      marginTop: viewportWidth <= 375 ? '50px' : 0
    }
  };
};

const TabModal = ({
  openTabModal,
  setOpenTabModal,
  activeTab,
  viewportWidth,
  data,
  colors,
  toHTML
}) => {
  return (
    <Modal
      isOpen={openTabModal}
      onRequestClose={() => setOpenTabModal(false)}
      style={customModalStyles(viewportWidth)}>
      <CloseModalWrapper activeColor={colors[activeTab]}>
        <CloseModal onClick={() => setOpenTabModal(false)} src={CloseModalWhite} />
      </CloseModalWrapper>
      <ModalInnerWrapper>
        <Content>
          <TabSection color={colors[activeTab]}>
            <TabTitle>
              {data[activeTab]?.tabLongerTitle
                ? data[activeTab].tabLongerTitle
                : data[activeTab].tabTitle}
            </TabTitle>
            {data[activeTab].richTextContent &&
              data[activeTab].richTextContent.displayRichTextAtTopOfTab && (
                <Markup content={toHTML(data[activeTab].richTextContent.content)} />
              )}
            {data[activeTab].tabHighlights && (
              <TabHighlights>
                {data[activeTab].tabHighlights.map((highlight, index) => (
                  <TabHighlight key={index}>
                    <IconContainer color={colors[activeTab]}>
                      <GatsbyImage
                        image={getImage(highlight.tabHighlightIcon)}
                        alt={highlight.title}
                      />
                    </IconContainer>
                    <div>
                      <h4>{highlight.title}</h4>
                      <Markup content={toHTML(highlight.description)} />
                    </div>
                  </TabHighlight>
                ))}
              </TabHighlights>
            )}
            {data[activeTab].richTextContent &&
              !data[activeTab].richTextContent.displayRichTextAtTopOfTab && (
                <Markup content={toHTML(data[activeTab].richTextContent.content)} />
              )}
          </TabSection>
        </Content>
      </ModalInnerWrapper>
    </Modal>
  );
};

const CloseModalWrapper = styled.div`
  background-color: ${({ activeColor }) => activeColor};
  border-radius: 50%;
  position: absolute;
  top: 10px;
  right: 10px;
  height: 24px;
  width: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.5rem;
  z-index: 1;
  cursor: pointer;
`;

const CloseModal = styled.img`
  height: 100%;
  width: 100%;
  z-index: 2;
  position: relative;
`;

const ModalInnerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  align-items: center;
  background: #ffffff;
  max-width: 620px;
  max-height: auto;
  width: 100%;
  height: 100%;
  border-radius: 4px;
  padding: 3rem 1.25rem;
  @media screen and (min-width: 620px) {
    height: 570px;
  }
`;

const Content = styled(motion.div)`
  display: flex;
  flex-direction: column;
  gap: 2rem;
  width: 100%;
  font-family: proxima-nova, sans-serif;
  p {
    margin-bottom: 1rem;
  }
  @media (min-width: 1024px) {
    margin: 3rem 3rem 3rem 6rem;
  }
`;

const TabSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  h3,
  a {
    color: ${({ color }) => color};
    font-weight: 600;
  }
`;

const TabTitle = styled(motion.h3)`
  font-family: adelle, serif;
  font-size: 1.25rem;
  font-weight: 600;
`;

const TabHighlights = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

const TabHighlight = styled.div`
  display: grid;
  grid-template-columns: 80px 1fr;
  gap: 1rem;
  div {
    display: flex;
    flex-direction: column;
    gap: 0.25rem;
    h4 {
      font-size: 1rem;
      font-weight: 600;
      font-family: adelle, serif;
      margin-top: 0.625rem;
    }
  }
`;

const IconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 64px;
  width: 64px;
  background-color: ${({ color }) => color};
  border-radius: 10px;
  position: relative;
  margin: 0 auto;
  z-index: 1;
  .gatsby-image-wrapper {
    z-index: 2;
  }
  &:before {
    content: '';
    display: block;
    height: 100%;
    width: 100%;
    background-color: ${({ color }) => color};
    opacity: 0.2;
    border-radius: 10px;
    transform: rotate(45deg);
    position: absolute;
    top: 0;
    left: 0;
    z-index: 0;
  }
`;

export default TabModal;
