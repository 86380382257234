import React from 'react';
import styled from 'styled-components';
import LargeImageCarouselShapes from 'images/shared/misc/large-image-carousel-shapes.svg';
import PointsPurpleBlueSVG from 'images/shared/misc/points_purple_blue.svg';

const FeaturedVideo = ({ video, viewportWidth }) => {
  return (
    <Wrapper>
      <Container>
        {viewportWidth > 1024 && (
          <>
            <Shapes src={LargeImageCarouselShapes} alt="Large Image Carousel Shapes" />
            <PointsPurpleBlue src={PointsPurpleBlueSVG} alt="Points Purple Blue" />
          </>
        )}
        <iframe
          src={video.embedLink}
          title={video.altText}
          width="690"
          height="371"
          frameBorder="0"></iframe>
      </Container>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 1.25rem;
`;

const Container = styled.div`
  width: 100%;
  height: 100%;
  max-height: 580px;
  max-width: 1136px;
  padding-top: 56.25%;
  position: relative;
  margin: 0 auto;
  iframe {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }
  @media (min-width: 1600px) {
    padding-top: 35%;
  }
`;

const Shapes = styled.img`
  position: absolute;
  top: 20%;
  left: -60px;
  height: 400px;
  width: auto;
  z-index: 0;
`;

const PointsPurpleBlue = styled.img`
  position: absolute;
  bottom: -20px;
  right: -110px;
  height: 250px;
  width: auto;
  z-index: 0;
`;

export default FeaturedVideo;
