import React from 'react';
import styled from 'styled-components';
import { GatsbyImage } from 'gatsby-plugin-image';

const KeyBenefits = ({ keyBenefits }) => {
  return (
    <Container>
      <Introduction>
        <h2>Key Benefits</h2>
      </Introduction>
      <Objectives>
        {keyBenefits.map((keyBenefit, index) => (
          <Objective key={index}>
            <IconContainer>
              {keyBenefit?.benefitIcon?.childImageSharp && (
                <GatsbyImage
                  image={keyBenefit.benefitIcon.childImageSharp.gatsbyImageData}
                  alt={keyBenefit.benefit}
                />
              )}
            </IconContainer>
            <p>{keyBenefit.benefit}</p>
          </Objective>
        ))}
      </Objectives>
    </Container>
  );
};

const Container = styled.div`
  max-width: 1145px;
  margin: 3rem auto;
  padding: 0 1.5rem;
`;

const Introduction = styled.div`
  max-width: 914px;
  padding: 0 1.5rem;
  margin: 0 auto;
  h2 {
    color: var(--dynamic-blue);
    font-family: adelle, serif;
    font-size: 1.9rem;
    font-weight: 600;
    text-align: center;
    margin-bottom: 2rem;
  }
  @media screen and (min-width: 1143px) {
    h2 {
      font-size: 2rem;
    }
  }
`;

const Objectives = styled.ul`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 auto;
  gap: 3rem;
  @media screen and (min-width: 1200px) {
    flex-direction: row;
  }
`;

const Objective = styled.li`
  display: grid;
  grid-template-rows: 90px auto;
  gap: 0.2rem;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-family: adelle, serif;
  font-size: 1rem;
  font-weight: 600;
`;

const IconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 64px;
  width: 64px;
  background-color: var(--dynamic-blue);
  border-radius: 10px;
  position: relative;
  margin: 0 auto;
  z-index: 1;
  &:before {
    content: '';
    display: block;
    height: 100%;
    width: 100%;
    background-color: var(--dynamic-blue);
    opacity: 0.2;
    border-radius: 10px;
    transform: rotate(45deg);
    position: absolute;
    top: 0;
    left: 0;
    z-index: 0;
  }
`;

export default KeyBenefits;
