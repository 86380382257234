import React from 'react';
import styled from 'styled-components';
import EventTypeIcon from 'images/activity/info_icons/EventType';
import ApproachIcon from 'images/activity/info_icons/Approach';
import DurationIcon from 'images/activity/info_icons/Duration';
import NumbersIcon from 'images/activity/info_icons/Numbers';
import LocationIcon from 'images/activity/info_icons/Location';

const Features = ({ features }) => {
  const { formatOfDeliveryAndEventSupport, approach, duration, numbers, activityLocation } =
    features;
  console.log(features);
  return (
    <Icons>
      {formatOfDeliveryAndEventSupport.length > 0 && (
        <Icon>
          <BlueArea>
            <EventTypeIcon />
            Event Type
          </BlueArea>
          <WhiteArea
            dangerouslySetInnerHTML={{
              __html: [
                ...new Set(
                  formatOfDeliveryAndEventSupport.map((format) =>
                    format.includes('In Person') ? 'In Person' : 'Virtual'
                  )
                )
              ].join(' Or ')
            }}></WhiteArea>
        </Icon>
      )}
      {approach && (
        <Icon>
          <BlueArea>
            <ApproachIcon />
            Approach
        </BlueArea>
          <WhiteArea>{approach}</WhiteArea>
        </Icon>
      )}
      {duration && (
        <Icon>
          <BlueArea>
            <DurationIcon />
          Duration
        </BlueArea>
          <WhiteArea>{duration}</WhiteArea>
        </Icon>
      )}
      {numbers && (
        <Icon>
          <BlueArea>
            <NumbersIcon />
          Numbers
        </BlueArea>
          <WhiteArea>{numbers}</WhiteArea>
        </Icon>
      )}
      {activityLocation && (
        <Icon>
          <BlueArea>
            <LocationIcon />
            Location
          </BlueArea>
          <WhiteArea
            dangerouslySetInnerHTML={{
              __html: activityLocation.join(' Or ')
            }}></WhiteArea>
        </Icon>
      )}
    </Icons>
  );
};

const Icons = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  flex-shrink: 1;
  max-width: 540px;
  margin: 0 auto;
  margin-top: -1rem;
  align-content: center;
  z-index: 3;
  gap: 1rem;
  position: relative;
  @media screen and (min-width: 566px) {
    flex-wrap: nowrap;
    justify-content: space-between;
  }

  @media screen and (min-width: 1100px) {
    max-width: 780px;
    margin: 0 auto;
    margin-top: -1rem;
  }
`;

const Icon = styled.div`
  width: 120px;
  height: 140px;
  display: grid;
  grid-template-rows: 2.5fr 1fr;
  font-family: 'proxima-nova', sans-serif;
  font-weight: 600;
  @media screen and (min-width: 566px) {
    width: 140px;
  }
  > div {
    border: 1px solid var(--dynamic-blue);
    font-size: 0.875rem;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

const BlueArea = styled.div`
  background-color: var(--dynamic-blue);
  color: #fff;
  border-radius: 10px 10px 0 0;
  display: flex;
  flex-direction: column;
  svg {
    margin-bottom: 8px;
  }
  @media screen and (min-width: 780px) {
    svg {
      height: 60px;
      width: 60px;
    }
  }
`;

const WhiteArea = styled.div`
  background-color: #fff;
  color: var(--dynamic-blue);
  border-radius: 0 0 10px 10px;
  padding: 0.5em;
  height: 46px;
  text-align: center;
  li {
    padding-bottom: 1rem;
  }
`;

export default Features;
