import * as React from 'react';
function SvgNumbers(props) {
  return (
    <svg width={64} height={64} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M20 51.68v-9.6c0-6.56 5.44-12 12-12s12 5.44 12 12v9.6M32 28.32a8 8 0 100-16 8 8 0 000 16z"
        stroke="#fff"
        strokeWidth={2}
        strokeMiterlimit={10}
      />
      <path
        d="M8 46.88v-5.76c0-4.48 3.52-8 8-8 2.24 0 4.16.96 5.6 2.4M16 30.88a4.8 4.8 0 100-9.6 4.8 4.8 0 000 9.6zM56 46.88v-5.76c0-4.48-3.52-8-8-8-2.24 0-4.16.8-5.6 2.24M48 30.88a4.8 4.8 0 100-9.6 4.8 4.8 0 000 9.6z"
        stroke="#fff"
        strokeWidth={2}
        strokeMiterlimit={10}
      />
    </svg>
  );
}
export default SvgNumbers;
