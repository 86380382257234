import React from 'react';
import styled, { css } from 'styled-components';
import { motion } from 'framer-motion';

const Button = (
  {
    borderRadius = '20px',
    buttonColor = '--dynamic-blue',
    buttonHoverColor = '--dynamic-dark-blue',
    // buttonPressedColor = '--dynamic-dark-grey',
    children,
    className,
    disabled,
    flexShrink = 1,
    fontColor = '--dynamic-white',
    fontHoverColor = '--dynamic-white',
    handleClick,
    height = '40px',
    innerRef,
    text,
    transformOrigin = 'center center',
    type = 'button',
    width = '144px',
    boxShadow = 'none',
    shine
  },
  ...rest
) => (
  <Wrapper
    borderRadius={borderRadius}
    buttonColor={buttonColor}
    buttonHoverColor={buttonHoverColor}
    className={className}
    // buttonPressedColor={buttonPressedColor}
    disabled={disabled}
    flexShrink={flexShrink}
    fontColor={fontColor}
    fontHoverColor={fontHoverColor}
    height={height}
    onClick={(e) => !disabled && handleClick && handleClick(e)}
    ref={innerRef}
    transformOrigin={transformOrigin}
    type={type}
    width={width}
    boxShadow={boxShadow}
    {...rest}>
    {shine && <Shine />}
    <span>
      {children}
      {text}
    </span>
  </Wrapper>
);

const Wrapper = styled(motion.button).attrs(({ ariaLabel }) => ({
  'aria-label': ariaLabel,
  initial: {
    opacity: 0
  },
  animate: {
    opacity: 1
  },
  exit: {
    opacity: 0
  },
  whileTap: {
    scale: 0.95
  }
}))`
  align-items: center;
  border-radius: ${({ borderRadius }) => borderRadius};
  border: none;
  box-shadow: ${({ boxShadow }) => boxShadow};
  cursor: pointer;
  display: flex;
  flex-shrink: ${({ flexShrink }) => flexShrink};
  height: ${({ height }) => height};
  justify-content: center;
  outline: none; /* TODO: Find more accessible way of hiding outline when button is clicked */
  overflow: hidden;
  width: ${({ width }) => width};
  position: relative;
  z-index: 2;
  ${({ style }) => style};

  * {
    pointer-events: none;
  }

  ${({ disabled }) =>
    disabled &&
    css`
      background-color: #bdbdbd !important;
      pointer-events: none;
    `}

  span {
    display: flex;
    color: ${({ fontColor }) => fontColor};
    align-items: center;
    font-size: 0.95rem;
    font-weight: bold;
    letter-spacing: 0.01em;
  }

  &:hover {
    background-color: ${({ buttonHoverColor }) => css`var(${buttonHoverColor})`};
    box-shadow: ${({ boxShadow }) => boxShadow && boxShadow};
    transition: background-color 0.125s ease-in-out, box-shadow 0.125s ease-in-out;
    transform-origin: ${({ transformOrigin }) => transformOrigin};

    span {
      color: ${({ fontHoverColor }) => css`var(${fontHoverColor})`};
      transition: color 0.125s ease-in-out;
    }
  }

  &:not(:hover) {
    background-color: ${({ buttonColor }) => css`var(${buttonColor})`};
    box-shadow: ${({ boxShadow }) => boxShadow};
    transform: scale3d(1, 1, 1);
    transition: background-color 0.125s ease-in-out, transform 0.125s ease-in-out,
      box-shadow 0.125s ease-in-out;
    transform-origin: ${({ transformOrigin }) => transformOrigin};

    span {
      color: ${({ fontColor }) => css`var(${fontColor})`};
      transition: color 0.125s ease-in-out;
    }
  }

  &:active {
    background-color: ${({ buttonPressedColor }) => css`var(${buttonPressedColor})`};
    transition: background-color 0.025s ease-in-out;

    span {
      color: ${({ fontPressedColor }) => fontPressedColor && css`var(${fontPressedColor})`};
      transition: color 0.025s ease-in-out;
    }
  }
`;

const Shine = styled.div`
  animation: slide 1s infinite;
  background: linear-gradient(
    to right,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0.8) 50%,
    rgba(128, 186, 232, 0) 99%,
    rgba(125, 185, 232, 0) 100%
  );
  content: '';
  height: 100%;
  position: absolute;
  top: 0;
  transform: translateX(100%);
  width: 100%;
  z-index: 1;

  @keyframes slide {
    0% {
      transform: translateX(-100%);
    }
    100% {
      transform: translateX(100%);
    }
  }
`;

export default Button;
