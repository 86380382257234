import React from 'react';
import { Button } from 'components';
import { Link } from 'gatsby';
import { scrollToAnchor } from 'utils';
import styled from 'styled-components';

const GetButton = ({
  buttonLinkPath,
  buttonText,
  buttonWidth,
  buttonHeight,
  ariaLabel,
  handleClick,
  viewportWidth,
  shine,
  marginTop = '1.375em'
}) => (
  <LinkWrapper marginTop={marginTop}>
    {handleClick ? (
      <Button
        handleClick={handleClick}
        text={buttonText}
        ariaLabel={buttonText}
        width={buttonWidth}
        height={buttonHeight}
        shine={shine}
      />
    ) : buttonLinkPath?.includes('https') ? (
      <a href={buttonLinkPath} target="_blank" style={{ textDecoration: 'none' }}>
        <Button
          text={buttonText}
          ariaLabel={buttonText}
          width={buttonWidth}
          height={buttonHeight}
        />
      </a>
    ) : buttonLinkPath === '#contact-us' ? (
      <div
        onClick={() =>
          scrollToAnchor('contact-us', {
            offset:
              viewportWidth < 360 ? -60 : viewportWidth < 375 ? -66 : viewportWidth < 967 ? -73 : -91
          })
        }>
        <Button
          text={buttonText}
          ariaLabel={buttonText}
          width={buttonWidth}
          height={buttonHeight}
          shine={shine}
        />
      </div>
    ) : (
      <Link to={buttonLinkPath}>
        <Button
          text={buttonText}
          ariaLabel={buttonText}
          width={buttonWidth}
          height={buttonHeight}
        />
      </Link>
    )}
  </LinkWrapper>
);

const LinkWrapper = styled.div`
  margin-top: ${({ marginTop }) => marginTop};
  display: inline-block;
  @media screen and (min-width: 500px) {
    margin-bottom: 0.625em;
  }
`;

export default GetButton;
