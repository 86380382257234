import * as React from 'react';
function SvgEventType(props) {
  return (
    <svg width={64} height={64} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M49.762 23.2V12M49.762 52V32.8M49.76 32.8a4.8 4.8 0 10.001-9.6 4.8 4.8 0 000 9.6zM32.002 32.8V12M32.002 52v-9.6M32.001 42.4a4.8 4.8 0 100-9.6 4.8 4.8 0 000 9.6zM14.242 23.2V12M14.242 52V32.8M14.241 32.8a4.8 4.8 0 100-9.6 4.8 4.8 0 000 9.6z"
        stroke="#fff"
        strokeWidth={2}
        strokeMiterlimit={10}
      />
    </svg>
  );
}
export default SvgEventType;
