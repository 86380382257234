import React from 'react';
import styled from 'styled-components';
import LightBlueBackgroundSVG from 'images/shared/misc/light_blue_tab.svg';
import RedCircleYellowPoint from 'images/shared/misc/red_circle_yellow_point.svg';
import PurpleCircle from 'images/shared/misc/purple_circle.svg';
import TabWidget from './TabWidget';

const LargeContent = ({ data, viewportWidth }) => {
  const { summary, title, tabWidget } = data;
  return (
    <Container>
      <LightBlueBackground src={LightBlueBackgroundSVG} />
      {viewportWidth > 1024 && (
        <>
          <RedCircleYellowPointSVG src={RedCircleYellowPoint} />
          <PurpleCircleSVG src={PurpleCircle} />
        </>
      )}
      <Content>
        <Introduction>
          <h2>{title}</h2>
          <p>{summary}</p>
        </Introduction>
        {tabWidget?.length > 0 && <TabWidget data={tabWidget} viewportWidth={viewportWidth} />}
      </Content>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2rem;
  justify-content: center;
  max-width: 1145px;
  width: 100%;
  padding: 0 1.5rem;
  @media (min-width: 1024px) {
    padding: 0;
  }
`;

const Introduction = styled.div`
  max-width: 914px;
  margin: 3rem auto 0 auto;
  padding: 0 1.5rem;

  h2 {
    color: var(--dynamic-blue);
    font-family: adelle, serif;
    font-size: 1.9rem;
    font-weight: 600;
    text-align: center;
    margin-bottom: 2rem;

    @media screen and (min-width: 1143px) {
      font-size: 2rem;
    }
  }

  p, span {
    color: var(--dynamic-dark-grey);
    font-family: 'proxima-nova', sans-serif;
    font-size: 1.125rem;
    font-weight: 400;
    font-style: normal;
    letter-spacing: 0.01em;
    margin-bottom: 1.75rem;
    text-align: center;
    a {
      color: var(--dynamic-blue);
      text-decoration: underline;
      font-weight: 600;
    }
  }
  @media screen and (min-width: 1200px) {
    font-size: 1.3rem;
    margin: 6rem auto 0 auto;
    padding: 0;
  }
`;

const LightBlueBackground = styled.img`
  position: absolute;
  top: -70px;
  left: 0;
  width: auto;
  height: 125%;
  @media (min-width: 1024px) {
    top: 0;
    width: 100%;
    height: auto;
  }
  @media (min-width: 1600px) {
    top: -100px;
  }
`;


const RedCircleYellowPointSVG = styled.img`
  position: absolute;
  bottom: -30%;
  right: 0;
  width: auto;
  height: 295px;
  z-index: 1;
`;

const PurpleCircleSVG = styled.img`   
  position: absolute;
  bottom: -40px;
  left: 0;
  width: auto;
  height: 160px;
`;




export default LargeContent;
