import React, { useState } from 'react';
import styled from 'styled-components';
import { AccordianButton } from 'images/shared/misc';
import { remark } from 'remark';
import remarkHTML from 'remark-html';
import { motion, AnimatePresence } from 'framer-motion';
import { Markup } from 'interweave';

const toHTML = (value) => remark().use(remarkHTML).processSync(value).toString();

const Accordian = ({ i, expanded, setExpanded, question, answer }) => {
  const isOpen = i === expanded;

  return (
    <Item role="button" onClick={() => setExpanded(isOpen ? null : i)} isOpen={isOpen}>
      <h4>
        {question} <div>{<AccordianButton open={isOpen} />}</div>
      </h4>
      <AnimatePresence mode="wait">
        {isOpen && (
          <AnswerText
            key="content"
            initial="collapsed"
            animate="open"
            exit="collapsed"
            isOpen={isOpen}
            variants={{
              open: {
                opacity: 1,
                height: 'auto',
                marginBottom: '2rem',
                transition: {
                  when: 'beforeChildren',
                  staggerChildren: 0.3
                }
              },
              collapsed: {
                opacity: 0,
                height: 0,
                marginBottom: '0',
                transition: {
                  when: 'afterChildren'
                }
              }
            }}
            transition={{ duration: 0.375, ease: [0.04, 0.62, 0.23, 0.98] }}>
            <Markup content={toHTML(answer)} />
          </AnswerText>
        )}
      </AnimatePresence>
    </Item>
  );
};

const FAQ = ({ faqs }) => {
  const [expanded, setExpanded] = useState(null);
  return (
    <Container>
      {faqs.map((faq, i) => (
        <Accordian
          i={i}
          key={i}
          expanded={expanded}
          setExpanded={setExpanded}
          question={faq.question}
          answer={faq.response}
        />
      ))}
    </Container>
  );
};

const Container = styled.section`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  max-width: 1145px;
  margin: 3rem auto;
  width: 100%;
  position: relative;
  z-index: 1;
`;

const Item = styled(motion.div)`
  background: ${({ isOpen }) => (isOpen ? 'white' : 'var(--dynamic-blue)')};
  border-radius: 10px;
  box-shadow: 3px 3px 7px rgba(189, 189, 189, 0.4);
  transition: all 0.2s ease-in-out;
  padding: 1.25rem 1.5rem;
  cursor: pointer;
  h4 {
    align-items: center;
    font-family: adelle, serif;
    font-weight: 600;
    gap: 1rem;
    color: ${({ isOpen }) => (isOpen ? 'var(--dynamic-purple)' : 'white')};
    font-size: 1.25rem !important;
    display: grid;
    grid-template-columns: 1fr 48px;
    margin: 0;
    text-align: left !important;
    div {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 0.5rem;
      svg {
        width: 100%;
        height: 100%;
      }
    }
  }
  &:hover {
    background: ${({ isOpen }) => (isOpen ? 'white' : 'var(--dynamic-purple)')};
  }
  @media (min-width: 1024px) {
    h4 {
      font-size: 1.9rem !important;
      gap: 2rem;
    }
  }
`;

const AnswerText = styled(motion.div)`
  max-width: 260px;
  padding-top: 1rem;
  font-family: proxima-nova, sans-serif;
  font-size: 1rem;
  ol,
  ul {
    margin-left: 1.5rem;
  }
  li {
    margin-bottom: 0.5rem;
    strong {
      font-weight: 600;
    }
  }
  p {
    margin-bottom: 1.5rem;
    a {
      color: var(--dynamic-purple);
      text-decoration: underline;
    }
  }
  @media (min-width: 500px) {
    max-width: 380px;
  }
  @media (min-width: 768px) {
    max-width: 620px;
  }
  @media (min-width: 1024px) {
    padding-top: 0;
    max-width: 890px;
  }
`;

export default FAQ;
