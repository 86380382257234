import React, { useState } from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { Button } from 'components';
import { Markup } from 'interweave';
import { remark } from 'remark';
import remarkHtml from 'remark-html';
import styled from 'styled-components';
import TabModal from './TabModal';

const TabWidget = ({ data, viewportWidth }) => {
  const [activeTab, setActiveTab] = useState(0);
  const [openTabModal, setOpenTabModal] = useState(false);
  const colors = [
    'var(--dynamic-blue)',
    'var(--dynamic-purple)',
    'var(--dynamic-red)',
    'var(--dynamic-yellow)',
    'var(--dynamic-blue)',
    'var(--dynamic-purple)',
    'var(--dynamic-red)',
    'var(--dynamic-yellow)'
  ];
  const toHTML = (value) => remark().use(remarkHtml).processSync(value).toString();
  return (
    <Container>
      {viewportWidth > 1024 ? (
        <Body>
          <Tabs>
            {data.map((tab, index) => (
              <Tab
                onClick={() => setActiveTab(index)}
                active={activeTab === index}
                activeColor={colors[index]}>
                {tab.tabTitle}
                {index === activeTab && (
                  <ActiveTabArrow
                    key={index}
                    initial={{ opacity: 0, right: '-30px' }}
                    animate={{ opacity: 1, right: '-20px' }}
                    transition={{ duration: 0.4 }}>
                    <svg
                      width="17"
                      height="26"
                      viewBox="0 0 17 26"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M1.96338 2.12329L12.57 12.7299L1.96338 23.3365"
                        stroke={colors[index]}
                        stroke-width="5"
                      />
                    </svg>
                  </ActiveTabArrow>
                )}
              </Tab>
            ))}
          </Tabs>
          <AnimatePresence>
            <Content
              activeColor={colors[activeTab]}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ duration: 0.4 }}>
              <ActiveTitle activeColor={colors[activeTab]}>
                {data[activeTab].tabLongerTitle
                  ? data[activeTab].tabLongerTitle
                  : data[activeTab].tabTitle}
              </ActiveTitle>
              {data[activeTab].richTextContent && data[activeTab].richTextContent.displayRichTextAtTopOfTab && (
                <Markup content={toHTML(data[activeTab].richTextContent.content)} />
              )}
              {data[activeTab].tabHighlights && (
                <TabHighlights>
                  {data[activeTab].tabHighlights.map((highlight, index) => (
                    <TabHighlight key={index}>
                      <IconContainer color={colors[activeTab]}>
                        <GatsbyImage
                          image={getImage(highlight.tabHighlightIcon)}
                          alt={highlight.title}
                        />
                      </IconContainer>
                      <div>
                        <h4>{highlight.title}</h4>
                        <Markup content={toHTML(highlight.description)} />
                      </div>
                    </TabHighlight>
                  ))}
                </TabHighlights>
              )}
              {data[activeTab].richTextContent && !data[activeTab].richTextContent.displayRichTextAtTopOfTab && (
                <Markup content={toHTML(data[activeTab].richTextContent.content)} />
              )}
            </Content>
          </AnimatePresence>
        </Body>
      ) : (
        <Tabs>
          {data.map((tab, index) => (
            <Tab key={index} activeColor={colors[index]}>
              {tab.tabTitle}
              <Button
                text="View More"
                ariaLabel="view more"
                buttonColor="white"
                fontColor={colors[index]}
                fontHoverColor={colors[index]}
                buttonHoverColor="white"
                buttonPressedColor="white"
                width="112px"
                handleClick={() => {
                  setOpenTabModal(true);
                  setActiveTab(index);
                }}
              />
            </Tab>
          ))}
        </Tabs>
      )}
      <TabModal
        openTabModal={openTabModal}
        setOpenTabModal={setOpenTabModal}
        viewportWidth={viewportWidth}
        data={data}
        colors={colors}
        toHTML={toHTML}
        activeTab={activeTab}
      />
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  z-index: 1;
`;

const Body = styled.div`
  width: 100%;
  background-color: white;
  border-radius: 10px;
  box-shadow: 0px 3px 3px 1px rgba(189, 189, 189, 0.4);
  display: flex;
  position: relative;
  min-height: 540px;
  @media (min-width: 1024px) {
    width: calc(100% - 320px);
    margin-left: auto;
  }
`;

const Content = styled(motion.div)`
  display: flex;
  flex-direction: column;
  gap: 2rem;
  width: 100%;
  font-family: proxima-nova, sans-serif;
  h3,
  a {
    color: ${({ activeColor }) => activeColor};
    font-weight: 600;
  }
  p {
    max-width: 560px;
  }
  @media (min-width: 1024px) {
    margin: 3rem 3rem 3rem 6rem;
  }
`;

const Tabs = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  @media (min-width: 1024px) {
    position: absolute;
    left: -320px;
    top: -10px;
  }
`;

const Tab = styled.div`
  width: 100%;
  height: auto;
  box-shadow: 0px 3px 3px 1px rgba(189, 189, 189, 0.4);
  box-sizing: border-box;
  background-color: ${({ activeColor }) => activeColor};
  color: white;
  cursor: pointer;
  border-radius: 10px;
  display: flex;
  align-items: center;
  font-family: adelle, serif;
  font-size: 1.25rem;
  font-weight: 600;
  margin-left: ${({ active }) => (active ? '-20px' : '0')};
  transition: all 0.4s ease;
  padding: 1.25rem 1.5rem;
  position: relative;
  gap: 0.5rem;
  button {
    margin-left: auto;
    min-width: 112px;
  }
  @media (min-width: 1024px) {
    width: 350px;
    height: 80px;
    background-color: ${({ active, activeColor }) => (active ? activeColor : 'white')};
    color: ${({ active }) => (active ? 'white' : 'var(--dynamic-grey)')};
    &:hover {
      background-color: ${({ activeColor }) => activeColor};
      color: white;
    }
  }
`;

const ActiveTabArrow = styled(motion.div)`
  position: absolute;
  background-color: white;
  right: -20px;
  top: 50%;
  transform: translateY(-50%);
  height: 40px;
  width: 40px;
  border-radius: 10px;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.15);
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 0.25rem;
`;

const ActiveTitle = styled(motion.h3)`
  font-family: adelle, serif;
  font-size: 1.25rem;
  font-weight: 600;
`;

const TabHighlights = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
`;

const TabHighlight = styled.div`
  display: grid;
  grid-template-columns: 80px 1fr;
  gap: 1rem;
  div {
    display: flex;
    flex-direction: column;
    gap: 0.25rem;
    margin: auto 0;
    h4 {
      font-size: 1rem;
      font-weight: 600;
      font-family: adelle, serif;
    }
  }
`;

const IconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 64px;
  width: 64px;
  background-color: ${({ color }) => color};
  border-radius: 10px;
  position: relative;
  margin: 0 auto;
  z-index: 1;
  .gatsby-image-wrapper {
    z-index: 2;
  }
  &:before {
    content: '';
    display: block;
    height: 100%;
    width: 100%;
    background-color: ${({ color }) => color};
    opacity: 0.2;
    border-radius: 10px;
    transform: rotate(45deg);
    position: absolute;
    top: 0;
    left: 0;
    z-index: 0;
  }
`;

export default TabWidget;
