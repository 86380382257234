import React from 'react';
import sizes from 'react-sizes';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import styled, { css } from 'styled-components';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';

const SingleCarousel = ({
  carouselImgs,
  children: customButtons,
  currentSlide,
  totalItems,
  handleSlideChange,
  innerRef,
  service,
  maxWidth = '710px',
  showIndicators = false,
  reverseControls = false,
  flip
}) => {

  return (
    <Wrapper maxWidth={maxWidth}>
      <StyledCarousel
        maxWidth={maxWidth}
        autoPlay={totalItems > 1}
        infiniteLoop
        onChange={(slideNumber) => handleSlideChange(slideNumber, service)}
        ref={innerRef && innerRef}
        selectedItem={currentSlide}
        totalItems={totalItems}
        showArrows={false}
        showStatus={false}
        showThumbs={false}
        showIndicators={showIndicators && totalItems > 1}
        interval={3000}
        transitionTime={500}
        flip={flip}
        reverseControls={reverseControls}>
        {carouselImgs.map((img) => {
          return (
            <div key={img.src || img.alt}>
              <GatsbyImage image={getImage(img.src || img.image)} alt={img.alt} />
            </div>
          );
        })}
      </StyledCarousel>
      {totalItems > 1 && customButtons}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  height: 100%;
  max-height: 710px;
  max-width: ${({ maxWidth }) => maxWidth};
  width: 100%;
`;

const StyledCarousel = styled(Carousel)`
  ${({ totalItems, flip, maxWidth, reverseControls }) => css`
    .carousel.carousel-slider {
      overflow: inherit !important;
    }

    .slide div {
      height: 100%;
      max-height: 710px;
      max-width: ${maxWidth};
      width: 100%;
    }

    .control-dots {
      bottom: calc(-16px - 1%) !important;
      display: flex !important;
      justify-content: center !important;
      left: ${!reverseControls ? 'calc(100% - ${18 * totalItems - 6}px)' : 'unset'} !important;
      right: ${!reverseControls ? 'unset' : '0'} !important;
      margin: 0 !important;
      width: unset !important;
      z-index: 1;

      .dot {
        background: #fff !important;
        border: 2px solid grey;
        box-shadow: none !important
        display: list-item !important;
        height: 12px !important;
        list-style: none !important;
        margin: 0 6px 0 0 !important;
        opacity: 0.6 !important;
        outline: 0 !important;
        transition: background 0.25s, opacity 0.25s !important;
        width: 12px !important;

        &.selected {
          background: #080808 !important;
          opacity: 0.75 !important;
        }
      }
    }

    ${flip &&
    css`
      .control-dots {
        left: ${!reverseControls ? 'calc(-100% + ${18 * totalItems}px)' : 'unset'} !important;
        right: ${!reverseControls ? 'unset' : '0'} !important;
      }
    `}
  `}
`;

const CarouselImg = styled(GatsbyImage)`
  background: transparent !important;
  height: 100%;
  max-height: 710px;
  max-width: 710px;
  width: 100%;
`;

export default sizes(({ width }) => ({ viewportWidth: width && width }))(SingleCarousel);
