import React from 'react';
import sizes from 'react-sizes';
import styled, { css } from 'styled-components';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import ArrowSVG from 'images/shared/misc/left_arrow.svg';

const CustomLeftArrow = ({ onClick, leftArrowOffset, viewportWidth }) => (
  <StyledCustomLeftArrow
    onClick={() => onClick()}
    leftArrowOffset={leftArrowOffset}
    viewportWidth={viewportWidth}>
    <img src={ArrowSVG} alt="" style={{ marginRight: '5px' }} />
  </StyledCustomLeftArrow>
);
const CustomRightArrow = ({ onClick, rightArrowOffset, viewportWidth }) => (
  <StyledCustomRightArrow
    onClick={() => onClick()}
    rightArrowOffset={rightArrowOffset}
    viewportWidth={viewportWidth}>
    <img src={ArrowSVG} alt="" style={{ marginLeft: '5px', transform: 'rotate(180deg)' }} />
  </StyledCustomRightArrow>
);

export default sizes(({ width }) => ({ viewportWidth: width && width }))(
  ({
    children: slides,
    autoPlaySpeed = 2000,
    viewportWidth,
    responsiveRules,
    leftArrowOffset,
    rightArrowOffset,
    wideCarousel = false
  }) => slides && (
    <CarouselAndArrowButtonsWrapper wideCarousel={wideCarousel}>
      <CarouselWrapper>
        <StyledCarousel
          customLeftArrow={
            <CustomLeftArrow leftArrowOffset={leftArrowOffset} viewportWidth={viewportWidth} />
          }
          customRightArrow={
            <CustomRightArrow rightArrowOffset={rightArrowOffset} viewportWidth={viewportWidth} />
          }
          responsive={responsiveRules}
          swipeable
          draggable
          showDots={false}
          ssr
          infinite
          autoPlay={false}
          autoPlaySpeed={autoPlaySpeed}
          keyBoardControl
          // transitionDuration={500}
          containerClass="carousel-container"
          dotListClass="custom-dot-list-style"
          itemClass="carousel-item">
          {slides}
        </StyledCarousel>
      </CarouselWrapper>
    </CarouselAndArrowButtonsWrapper>
  )
);

const CarouselAndArrowButtonsWrapper = styled.div`
  width: ${({ wideCarousel }) => wideCarousel ? '180%' : '100%'};
  max-width: ${({ wideCarousel }) => wideCarousel ? '1200px' : '440px'};
  margin: 0 auto;
  position: relative;
  left: ${({ wideCarousel }) => wideCarousel ? '-35%' : '0'};

  @media screen and (min-width: 690px) {
    left: 4px;
    max-width: 553px;
    width: 100%;
    left: 0;
  }

  @media screen and (min-width: 972px) {
    left: 5.5px;
    max-width: 834px;
  }

  @media screen and (min-width: 1258px) {
    left: 3px;
    max-width: 1200px;
  }
`;

const CarouselWrapper = styled.div`
  /* background: red; */
  max-width: 1100px;
  margin: 0 auto;
  /* position: static !important; */
  width: 100%;
`;

const StyledCarousel = styled(Carousel)`
  position: static !important;

  .react-multi-carousel-dot-list {
    bottom: -33px;
  }

  ${({ children }) => children.length < 3 && css`
    .react-multi-carousel-track  {
      transform: translate3d(0, 0px, 0px) !important;
      margin: 0 auto !important;
    }
  `}
`;

const StyledCustomArrow = styled.div`
  position: absolute !important;
  background-color: var(--dynamic-blue);
  cursor: pointer;
  width: 40px;
  height: 40px;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;

  ${({ viewportWidth }) =>
    viewportWidth < 690 &&
    css`
      bottom: -20px;
    `};

  &:hover {
    transform: scale(1.2);
    transition: transform 0.2s;
  }

  &:not(:hover) {
    transform: scale(1);
    transition: transform 0.2s;
  }
`;

const StyledCustomLeftArrow = styled(StyledCustomArrow)`
  ${({ leftArrowOffset }) =>
    css`
      left: ${leftArrowOffset};
    `};
`;

const StyledCustomRightArrow = styled(StyledCustomArrow)`
  ${({ rightArrowOffset }) =>
    css`
      right: ${rightArrowOffset};
    `};
`;
