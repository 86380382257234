import React from 'react';
import styled from 'styled-components';
import { Button } from 'components';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import BluePurplePointsSVG from 'images/event_management/blue_purple_points.svg';
import YellowArrowSVG from 'images/event_management/yellow_arrow.svg';
import RedCircleSVG from 'images/event_management/red_circle.svg';

const EventManagementCallToAction = ({ content, brochureUrl, lessMarginBottom }) => {
  return (
    <OuterContainer lessMarginBottom={lessMarginBottom}>
      <BluePurplePoints src={BluePurplePointsSVG} alt="blue and purple points" />
      <YellowArrow src={YellowArrowSVG} alt="yellow arrow" />
      <RedCircle src={RedCircleSVG} alt="red circle" sendBack={brochureUrl} />
      <Container key={content.title}>
        <GatsbyImage
          image={getImage(content.thumbnail.childImageSharp.gatsbyImageData)}
          alt={content.title}
        />
        <TextContainer>
          <div style={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
            <h4>{content.title}</h4>
            {content.description && <p>{content.description}</p>}
          </div>
          <ButtonsContainer>
            <Button
              handleClick={() => {
                scrollToAnchor('contact-us', {
                  offset: -91
                });
              }}
              buttonColor="--dynamic-yellow"
              buttonHoverColor="--dynamic-red"
              fontColor="--dynamic-dark-grey"
              boxShadow="0px 4px 4px rgba(0, 0, 0, 0.15)"
              text="Contact Us"
            />
            {content.includeDownloadBrochureButton && brochureUrl && (
              <Button
                handleClick={() => window.open(brochureUrl, '_blank')}
                buttonColor="--dynamic-yellow"
                buttonHoverColor="--dynamic-red"
                fontColor="--dynamic-dark-grey"
                boxShadow="0px 4px 4px rgba(0, 0, 0, 0.15)"
                width="172px"ƒ
                text="Download Brochure"
              />
            )}
          </ButtonsContainer>
        </TextContainer>
      </Container>
    </OuterContainer>
  );
};

const OuterContainer = styled.div`
  position: relative;
  max-width: 320px;
  margin: 3rem auto;
  overflow: visible;
  @media (min-width: 1024px) {
    margin: 4rem auto ${({ lessMarginBottom }) => (lessMarginBottom ? '0' : '8rem')} auto;
    max-width: 1145px;
  }
`;

const Container = styled.div`
  background-color: var(--dynamic-blue);
  border-radius: 10px;
  color: white;
  display: grid;
  grid-template-rows: 152px auto;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  position: relative;
  z-index: 2;
  .gatsby-image-wrapper {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
  @media (min-width: 1024px) {
    grid-template-rows: 1fr;
    grid-template-columns: 444px 1fr;
  }
`;

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 1rem;
  padding: 1.5rem 1.25rem;
  h4 {
    font-family: adelle, serif;
    font-size: 1.5rem;
    font-weight: 600;
    line-height: 1.2;
    max-width: 460px;
  }
  p {
    font-family: 'proxima-nova', sans-serif;
    font-size: 1rem;
    font-weight: 400;
    font-style: normal;
    letter-spacing: 0.01em;
    max-width: 415px;
    strong {
      font-weight: 600;
    }
  }
  @media (min-width: 1024px) {
    flex-direction: row;
    align-items: flex-end;
    padding: 1.75rem 1.5rem;
    h4 {
      font-size: 1.825rem;
    }
  }
`;

const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
  gap: 0.5rem;
`;

const BluePurplePoints = styled.img`
  left: -76px;
  position: absolute;
  bottom: -50px;
  display: none;
  height: 178px;
  width: auto;
  z-index: 1;
  @media (min-width: 1024px) {
    display: block;
  }
`;

const YellowArrow = styled.img`
  position: absolute;
  right: -60px;
  bottom: -30px;
  height: 150px;
  width: auto;
  z-index: 1;
  transform: rotate(130deg);
  @media (min-width: 1024px) {
    right: 0;
    top: -50px;
    transform: rotate(0deg);
  }
`;

const RedCircle = styled.img`
  position: absolute;
  right: 6px;
  bottom: -50px;
  height: 106px;
  width: auto;
  z-index: 1;
  @media (min-width: 1024px) {
    z-index: ${({ sendBack }) => (sendBack ? '3' : '1')};
    bottom: unset;
    right: -50px;
    top: -50px;
  }
`;

export default EventManagementCallToAction;
