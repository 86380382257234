import React, { PureComponent } from 'react';
import sizes from 'react-sizes';
import styled, { css } from 'styled-components';
import { Link } from 'gatsby';
import { Button, SectionTitle, Card } from 'components';
import { InView } from 'react-intersection-observer';
import LightBlueBackgroundSVG from 'images/shared/misc/light_blue_background_1.svg';
import BlobPurpleYellowSVG from 'images/activity/misc/blob_purple_yellow.svg';

class SimilarCards extends PureComponent {
  state = {
    animateLightBlueBackgroundSVG: false
  };

  handleSVG = (animateLightBlueBackgroundSVG) => this.setState({ animateLightBlueBackgroundSVG });
  render() {
    const {
      paddingStyle,
      pathname,
      similarCards,
      viewportWidth,
      hideButton,
      hideTitle,
      hideBlob,
      title
    } = this.props;
    const { animateLightBlueBackgroundSVG } = this.state;
    const onCaseStudyPage = pathname.includes('/case-studies/');
    const onBlogPage = pathname.includes('/blog/');
    const onEventManagementServicePage = pathname.includes('event-management/');
    const onEventManagementPage = pathname === 'event-management/';
    const onTeamTrainingPage = pathname.includes('team-training');
    const onTeamBuildingActivitiesPage = pathname.includes('team-building/');
    const onLocationPage = pathname.includes('/locations/');
    const onLocationsPage = pathname === '/locations/';
    return (
      similarCards && (
        <Wrapper paddingStyle={paddingStyle}>
          {!hideButton && !hideBlob && (
            <LightBlueBackground
              animateLightBlueBackgroundSVG={animateLightBlueBackgroundSVG}
              src={LightBlueBackgroundSVG}
              alt="light blue background"
              // animationDuration={`${viewportWidth / 1920}s`}
              animationDuration="0.75s"
            />
          )}
          <InView onChange={this.handleSVG} threshold={0.99} triggerOnce>
            {!hideTitle && (
              <SectionTitle
                title={
                  title
                    ? title
                    : onEventManagementPage
                    ? 'Blogs'
                    : onEventManagementServicePage || onCaseStudyPage
                    ? 'Related Case Studies'
                    : onBlogPage
                    ? 'Similar Blog Posts'
                    : onTeamTrainingPage
                    ? 'Similar Team Training Activities'
                    : onLocationPage
                    ? 'Related Blog Posts'
                    : onTeamBuildingActivitiesPage
                    ? 'Alternative Activities'
                    : 'Similar Activities'
                }
                paddingStyle="2.8em 0 1.225em"
              />
            )}
          </InView>
          {/* TODO: DRY refactor this with grid on Team Activities page */}
          <Grid>
            {similarCards.map(({ node: similarActivityOrCaseStudy }) => {
              const { title, summary, featuredImg, graphic } =
                similarActivityOrCaseStudy.childMarkdownRemark.frontmatter;
              return (
                <Card
                  featuredImg={featuredImg}
                  graphic={graphic}
                  title={title
                    .split(' ')
                    .map((word) => word.slice(0, 1).toUpperCase() + word.slice(1))
                    .join(' ')}
                  summary={summary}
                  pathname={pathname !== '/event-management/' ? pathname : '/blog/'}
                  key={similarActivityOrCaseStudy.id}
                />
              );
            })}
          </Grid>
          {!hideButton && (
            <Link
              to={
                onLocationsPage
                  ? '/locations/'
                  : onEventManagementServicePage || onCaseStudyPage
                  ? '/our-work/'
                  : onBlogPage
                  ? '/blog/'
                  : onTeamTrainingPage
                  ? '/team-training'
                  : '/team-building/'
              }>
              <Button
                ariaLabel={
                  onEventManagementServicePage || onCaseStudyPage
                    ? 'Search All Case Studies'
                    : onBlogPage
                    ? 'Search All Blog Posts'
                    : onLocationsPage
                    ? 'Search All Locations'
                    : 'Search All Activities'
                }
                width={
                  onEventManagementServicePage || onCaseStudyPage || onBlogPage ? '234px' : '184px'
                }
                text={
                  onEventManagementServicePage || onCaseStudyPage
                    ? 'Search All Case Studies'
                    : onBlogPage
                    ? 'Search All Blog Posts'
                    : 'Search All Activities'
                }
              />
            </Link>
          )}
        </Wrapper>
      )
    );
  }
}

const Wrapper = styled.section`
  position: relative;
  ${({ paddingStyle }) => paddingStyle && `padding: ${paddingStyle}`};
  .gatsby-image-wrapper {
    max-height: 200px;
  }
  @media screen and (min-width: 426px) {
    padding-left: 0;
    padding-right: 0;
  }

  a {
    text-decoration: none;
  }

  button {
    margin: 52px auto 98px;
  }
`;

/* TODO: Refactor animation duration functionality */
const LightBlueBackground = styled.img`
  margin-left: 50%;
  position: absolute;
  top: -83.5px;
  transform: translate3d(100%, 0, 0);
  width: 2560px;

  ${({ animateLightBlueBackgroundSVG, animationDuration }) =>
    animateLightBlueBackgroundSVG &&
    css`
      transform: translate3d(-50%, 0, 0);
      transition: transform ${animationDuration} ease-in-out;
    `};
`;

const StyledBlobPurpleYellowSVG = styled.img`
  position: absolute;
  top: 0;
  left: 0;
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(294px, 350px));
  grid-auto-rows: minmax(420px, auto);
  grid-gap: 30px 25px;
  justify-content: center;
`;

export default sizes(({ width }) => ({ viewportWidth: width && width }))(SimilarCards);
