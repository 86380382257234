import React from 'react';
import styled from 'styled-components';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';

const colors = [
  'var(--dynamic-blue)',
  'var(--dynamic-red)',
  'var(--dynamic-purple)',
  'var(--dynamic-blue)',
  'var(--dynamic-yellow)'
];

const HowItWorks = ({ howItWorks }) => {
  const { title, summary, highlights } = howItWorks;
  return (
    <Wrapper>
      <h2>{title}</h2>
      {summary && <p>{summary}</p>}
      <Highlights>
        {highlights &&
          highlights.map((highlight, index) => (
            <Highlight key={index}>
              <IconContainer color={colors[index]}>
                <GatsbyImage image={getImage(highlight.howItWorksIcon)} alt={highlight.title} />
              </IconContainer>
              <TextContainer>
                <h3>{highlight.title}</h3>
                <p>{highlight.description}</p>
              </TextContainer>
            </Highlight>
          ))}
      </Highlights>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;
  h2 {
    color: var(--dynamic-blue);
    font-family: adelle, serif;
    font-size: 2rem;
    font-weight: 600;
    text-align: left;
  }
  p {
    font-family: 'proxima-nova', sans-serif;
  }
  @media (min-width: 768px) {
    gap: 3rem;
  }
`;

const Highlights = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
`;

const Highlight = styled.div`
  display: grid;
  grid-template-columns: 64px 1fr;
  gap: 2rem;
`;

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  text-align: left;
  gap: 0.125rem;
  h3 {
    font-family: adelle, serif;
    font-size: 1rem;
    font-weight: 600;
    margin: 0;
  }
`;

const IconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 64px;
  width: 64px;
  background-color: ${({ color }) => color};
  border-radius: 10px;
  position: relative;
  margin: 0 auto;
  z-index: 1;
  &:before {
    content: '';
    display: block;
    height: 100%;
    width: 100%;
    background-color: ${({ color }) => color};
    opacity: 0.2;
    border-radius: 10px;
    transform: rotate(45deg);
    position: absolute;
    top: 0;
    left: 0;
    z-index: 0;
  }
`;

export default HowItWorks;
