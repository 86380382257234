import React, { PureComponent } from 'react';
import sizes from 'react-sizes';
import { StaticQuery, graphql } from 'gatsby';
import { Cards } from 'components';
import styled, { css } from 'styled-components';
import { remark } from 'remark';
import remarkHTML from 'remark-html';
import { Markup } from 'interweave';
import CardsIntro from './CardsIntro';
import { sortByGridOrder } from 'utils';
import { InView } from 'react-intersection-observer';
import { Button } from 'components';
import { scrollToAnchor } from 'utils';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import BlobPurpleBlueSVG from 'images/event_management/blob_purple_blue.svg';
import BlobRedYellowSVG from 'images/event_management/blob_red_yellow.svg';
import LightBlueBackgroundSVG from 'images/event_management/light_blue_background.svg';

const toHTML = (value) => remark().use(remarkHTML).processSync(value).toString();

const colors = [
  'var(--dynamic-purple)',
  'var(--dynamic-red)',
  'var(--dynamic-yellow)',
  'var(--dynamic-blue)'
];

class Services extends PureComponent {
  state = {
    animateLightBlueBackgroundSVG: false,
    animateLightPurpleBackgroundSVGL: false
  };

  handleBlueBackgroundSVG = () => this.setState({ animateLightBlueBackgroundSVG: true });

  handlePurpleBackgroundSVG = () => this.setState({ animateLightPurpleBackgroundSVG: true });

  render() {
    const { viewportWidth, pathname, introduction, whyChoose, process } = this.props;
    const { animateLightBlueBackgroundSVG, animateLightPurpleBackgroundSVG } = this.state;

    return (
      <StaticQuery
        query={graphql`
          {
            eventManagementServices: allFile(
              filter: {
                sourceInstanceName: { eq: "cms_data_event_management_services" }
                ext: { eq: ".md" }
              }
              sort: { childMarkdownRemark: { frontmatter: { title: ASC } } }
            ) {
              edges {
                node {
                  id
                  childMarkdownRemark {
                    frontmatter {
                      title
                      summary
                      featuredImg {
                        alt
                        src {
                          childImageSharp {
                            gatsbyImageData(
                              width: 350
                              height: 200
                              transformOptions: { cropFocus: CENTER }
                              quality: 35
                              pngOptions: { compressionSpeed: 10 }
                              placeholder: TRACED_SVG
                              formats: [AUTO, WEBP]
                            )
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
            gridOrder: file(sourceInstanceName: { eq: "cms_data_settings_grid_orders" }) {
              childMarkdownRemark {
                frontmatter {
                  eventManagementServices {
                    title
                  }
                }
              }
            }
          }
        `}
        render={({ eventManagementServices, gridOrder }) => {
          const showTheseCards = gridOrder
            ? sortByGridOrder(eventManagementServices, gridOrder)
            : eventManagementServices.edges;
          return (
            <Wrapper>
              <InView onChange={this.handleBlueBackgroundSVG} triggerOnce>
                <CardsIntro introduction={introduction} toHTML={toHTML} />
              </InView>
              <div style={{ position: 'relative' }}>
                {eventManagementServices && (
                  <Cards showTheseCards={showTheseCards} pathname={pathname} />
                )}
                <LightBlueBackground
                  animateLightBlueBackgroundSVG={animateLightBlueBackgroundSVG}
                  src={LightBlueBackgroundSVG}
                  alt="light blue background"
                  animationDuration="0.75s"
                />
                {viewportWidth > 900 && (
                  <>
                    <BlobPurpleBlue src={BlobPurpleBlueSVG} alt="purple and blue blob" />
                    <BlobYellowRed src={BlobRedYellowSVG} alt="red and yellow blob" />
                  </>
                )}
              </div>
              <DetailedOverview>
                <Section>
                  <div>
                    <h3>
                      <Markup content={whyChoose.title.replace('Why Choose ', 'Why Choose<br />')} />
                    </h3>
                    <Markup content={toHTML(whyChoose.summary)} />
                    <Button
                      text="Contact Us"
                      ariaLabel="contact us"
                      buttonColor="--dynamic-purple"
                      buttonHoverColor="--dynamic-red"
                      buttonPressedColor="--dynamic-pressed-red"
                      handleClick={() => {
                        scrollToAnchor('contact-us', {
                          offset:
                            viewportWidth < 360 ? -60 : viewportWidth < 375 ? -66 : viewportWidth < 967 ? -72 : -91
                        });
                      }}
                    />
                  </div>
                  <div>
                    <h4>Choose Us For</h4>
                    <Highlights centerDiv={whyChoose.highlights.length % 2 != 0}>
                      {whyChoose.highlights.map((highlight, index) => (
                        <div key={highlight.title}>
                          <IconContainer color={colors[index]}>
                            <GatsbyImage image={getImage(highlight.whyChooseIcon)} alt={highlight.title} />
                          </IconContainer>
                          <h5>{highlight.title}</h5>
                          <p>{highlight.description}</p>
                        </div>
                      ))}
                    </Highlights>
                  </div>
                </Section>
                <Section style={{ alignItems: 'start', flexDirection: 'column-reverse' }}>
                  <div>
                    <Highlights centerDiv={process.highlights.length % 2 != 0}>
                      {process.highlights.map((highlight, index) => (
                        <div key={highlight.title}>
                          <IconContainer color={colors[index]}>
                            <GatsbyImage image={getImage(highlight.icon)} alt={highlight.title} />
                          </IconContainer>
                          <h5>{highlight.title}</h5>
                          <p>{highlight.description}</p>
                        </div>
                      ))}
                    </Highlights>
                  </div>
                  <div>
                    <h3>
                      <Markup content={process.title} />
                    </h3>
                    <Markup content={toHTML(process.summary)} />
                    <Button
                      text="Contact Us"
                      ariaLabel="contact us"
                      buttonColor="--dynamic-purple"
                      buttonHoverColor="--dynamic-red"
                      buttonPressedColor="--dynamic-pressed-red"
                      handleClick={() => {
                        scrollToAnchor('contact-us', {
                          offset:
                            viewportWidth < 360 ? -60 : viewportWidth < 375 ? -66 : viewportWidth < 967 ? -72 : -91
                        });
                      }}
                    />
                  </div>
                </Section>
              </DetailedOverview>
            </Wrapper>
          );
        }}
      />
    );
  }
}

const Wrapper = styled.section`
  margin-bottom: 0;
  padding: 5% 6.181% 0;
  position: relative;

  @media screen and (min-width: 1440px) {
    padding: 80px 0 0;
  }
`;

const DetailedOverview = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  max-width: 1146px;
  margin: 3rem auto;
  gap: 2rem;
  z-index: 1;
  white-space: pre-wrap;
  @media screen and (min-width: 1143px) {
    margin: 6rem auto;
  }
`;

const Section = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;
  h3,
  h4,
  h5 {
    font-family: adelle, serif;
   font-weight: 600;
  }
  h3 {
    color: var(--dynamic-purple);
    font-size: 1.9rem;
    margin-bottom: 0.5em;
    max-width: 440px;
  }
  p {
    margin-bottom: 1.5rem;
  }
  h4 {
    color: var(--dynamic-blue);
    font-size: 1.5rem;
    margin-bottom: 1.25rem;
    text-align: center;
  }
  h5 {
    font-size: 1.375rem;
    text-align: center;
    margin-top: 0;
    margin-bottom: 0;
    /* white-space: nowrap; */
  }
  > div {
    margin: 0 auto;
  }
  @media screen and (min-width: 1143px) {
    align-items: center;
    display: grid;
    gap: 3rem;
    grid-template-columns: 1fr 1fr;
    margin: 3rem 0;
    h3 {
      font-size: 2rem;
      margin-bottom: 1em;
    }
    h4 {
      margin-bottom: 1em;
    }
  }
`;

const Highlights = styled.div`
  display: flex;
  flex-direction: column;
  > div {
    align-items: center;
    display: flex;
    flex-direction: column;
    gap: 0.75rem;
    padding: 1rem 2rem 2rem 2rem;
    position: relative;
    &:not(:last-of-type) {
      &::before {
        content: '';
        height: 1px;
        background: var(--dynamic-blue);
        width: 160px;
        position: absolute;
        right: 50%;
        bottom: 0;
        opacity: 0.2;
        transform: translateX(50%);
      }
    }
    p {
      font-size: 0.75rem;
      font-weight: 500;
      text-align: center;
      max-width: 240px;
      margin: 0 auto;
    }
  }
  @media (min-width: 768px) {
    font-size: 1rem;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    > div {
      &:first-of-type {
        &::after {
          content: '';
          height: 160px;
          background: var(--dynamic-blue);
          width: 1px;
          position: absolute;
          right: 0;
          bottom: 0.75rem;
          opacity: 0.2;

        }
        &::before {
          content: '';
          height: 1px;
          background: var(--dynamic-blue);
          width: 160px;
          position: absolute;
          right: 0.75rem;
          bottom: 0;
          opacity: 0.2;
          transform: unset;
        }
      }
      &:nth-of-type(2) {
        &::before {
          content: '';
          height: 1px;
          background: var(--dynamic-blue);
          width: 160px;
          position: absolute;
          left: 0.75rem;
          bottom: 0;
          opacity: 0.2;
          transform: unset;
        }
      }
      &:nth-of-type(3) {
        &::before {
          content: '';
          height: 0;
          background: var(--dynamic-blue);
          width: 0;
          position: absolute;
          left: 0.75rem;
        }
      }
      &:last-of-type {
        grid-column: ${({ centerDiv }) => (centerDiv ? 'span 2' : 'span 1')};
      }
      &:nth-of-type(4) {
        &::before {
          content: '';
          height: 160px;
          background: var(--dynamic-blue);
          width: 1px;
          position: absolute;
          left: 0rem;
          top: 0.75rem;
          opacity: 0.2;
        }
      }
    }
  }
`;

const BlobYellowRed = styled.img`
  position: absolute;
  right: 0;
  top: -100px;
  height: 250px;
  width: auto;
`;

const BlobPurpleBlue = styled.img`
  left: 0;
  position: absolute;
  bottom: -100px;
  height: 250px;
  width: auto;
`;

const LightBlueBackground = styled.img`
  position: absolute;
  top: 0;
  transform: translate3d(100%, 0, 0);
  height: 100%;
  width: auto;
  left: 50%;
  transform: translateX(-50%);
  max-height: 1450px;
    @media screen and (min-width: 1440px) {
    width: 100%;
    height: auto;
    left: 0;
    transform: unset;
    ${({ animateLightBlueBackgroundSVG, animationDuration }) =>
    animateLightBlueBackgroundSVG &&
    css`
      transform: translate3d(0, 0, 0);
      transition: transform ${animationDuration} ease-in-out;
    `};
  }
`;

const IconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 64px;
  width: 64px;
  background-color: ${({ color }) => color};
  border-radius: 10px;
  position: relative;
  margin: 0 auto;
  z-index: 1;
  &:before {
    content: '';
    display: block;
    height: 100%;
    width: 100%;
    background-color: ${({ color }) => color};
    opacity: 0.2;
    border-radius: 10px;
    transform: rotate(45deg);
    position: absolute;
    top: 0;
    left: 0;
    z-index: 0;
  }
`;

export default sizes(({ width }) => ({ viewportWidth: width && width }))(Services);
