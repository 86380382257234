import React from 'react';
import sizes from 'react-sizes';
import PhoneIcon from 'images/shared/header/PhoneIcon';
import MailIcon from 'images/shared/header/MailIcon';
import styled, { css } from 'styled-components';

const PhoneAndEmailTab = ({ onMobile }) => (
  <Wrapper onMobile={onMobile}>
    <a href="tel:+353 1 554 5784">
      <StyledPhoneIcon alt="Phone icon" onMobile={onMobile} />
      <span>+353 1 554 5784</span>
    </a>
    <a href="mailto:info@dynamicevents.ie">
      <StyledMailIcon alt="Mail icon" onMobile={onMobile} />
      <span>info@dynamicevents.ie</span>
    </a>
  </Wrapper>
);

const Wrapper = styled.div`
  align-items: center;
  background-color: ${({ onMobile }) => (onMobile ? '#fff' : 'var(--dynamic-blue)')};
  border-radius: ${({ onMobile }) => (onMobile ? '28px 28px 0 0' : '0 0 28px 28px')};
  display: flex;
  font-size: 0.625rem;
  height: 28px;
  justify-content: space-around;
  left: 50%;
  margin: 0 auto;
  padding: 0 1.5em;
  position: absolute;
  top: ${({ onMobile }) => (onMobile ? css`calc(100% - 28px)` : 0)};
  transform: translate3d(-50%, 0, 0);
  width: 320px;
  z-index: 4;

  a {
    height: 12px;
    display: flex;
    align-content: center;
    position: relative;
    bottom: 1px;
    text-decoration: none;
  }

  span {
    color: ${({ onMobile }) => (onMobile ? 'var(--dynamic-dark-grey)' : '#fff')};
    letter-spacing: 0.02em;
    font-weight: 300;
    font-size: 0.7rem;
    margin-left: 9px;
    font-family: 'proxima-nova', sans-serif;
  }
`;

const StyledPhoneIcon = styled(PhoneIcon)`
  path {
    fill: ${({ onMobile }) => (onMobile ? 'var(--dynamic-dark-grey)' : '#fff')};
  }
`;
const StyledMailIcon = styled(MailIcon)`
  path {
    fill: ${({ onMobile }) => (onMobile ? 'var(--dynamic-dark-grey)' : '#fff')};
  }
`;

export default sizes(({ width }) => ({ viewportWidth: width && width }))(PhoneAndEmailTab);
