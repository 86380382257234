import React, { useState, createRef } from 'react';
import styled from 'styled-components';
import { SingleCarousel, SmallCustomCarouselButtons } from 'components';
import LargeImageCarouselShapes from 'images/shared/misc/large-image-carousel-shapes.svg';
import PointsPurpleBlueSVG from 'images/shared/misc/points_purple_blue.svg';

const WideImageGallery = ({ images, viewportWidth }) => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const totalItems = images.length;
  const carouselRef = createRef();
  const goToPreviousSlide = () => {
    setCurrentSlide((prevState) => {
      if (prevState !== 0) {
        return prevState - 1;
      }
      return prevState;
    });
  };
  const goToNextSlide = () => {
    setCurrentSlide((prevState) => {
      if (prevState !== totalItems - 1) {
        return prevState + 1;
      }
      return prevState;
    });
  };
  const handleSlideChange = (currentSlide) => {
    setCurrentSlide(currentSlide);
  };

  return (
    <Container>
      <CarouselWrapper>
        {viewportWidth > 1024 && (
          <>
            <Shapes src={LargeImageCarouselShapes} alt="Large Image Carousel Shapes" />
            <PointsPurpleBlue src={PointsPurpleBlueSVG} alt="Points Purple Blue" />
          </>
        )}
        <SingleCarousel
          carouselImgs={images}
          showIndicators
          innerRef={carouselRef}
          totalItems={totalItems}
          currentSlide={currentSlide}
          handleSlideChange={handleSlideChange}
          reverseControls={true}
          maxWidth="1136px">
          <SmallCustomCarouselButtons
            goToPreviousSlide={goToPreviousSlide}
            goToNextSlide={goToNextSlide}
            disableLeftArrow={currentSlide === 0}
            disableRightArrow={currentSlide === totalItems - 1}
            leftArrowOffset={viewportWidth < 1100 ? 'calc(50% - 45px)' : 'calc(5% - 43px)'}
            rightArrowOffset={viewportWidth < 1100 ? 'calc(50% - 45px)' : 'calc(95% - 43px)'}
          />
        </SingleCarousel>
      </CarouselWrapper>
    </Container>
  );
};

const Container = styled.div`
  margin: 3rem auto;
  position: relative;
  height: 100%;
  padding: 0 1.5rem;
  z-index: 1;
  @media screen and (min-width: 1024px) {
    margin: 6rem auto;
  }
`;

const CarouselWrapper = styled.div`
  max-width: 100%;
  margin: 0 auto;
  position: relative;
  width: 100%;
  @media screen and (min-width: 1024px) {
    max-width: 1136px;
  }
`;

const Shapes = styled.img`
  position: absolute;
  top: 35%;
  left: -60px;
  height: 295px;
  width: auto;
  z-index: 0;
`;

const PointsPurpleBlue = styled.img`
  position: absolute;
  bottom: -100px;
  right: -100px;
  height: 250px;
  width: auto;
  z-index: 0;
`;

export default WideImageGallery;
