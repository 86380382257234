import React from 'react';
import sizes from 'react-sizes';
import styled from 'styled-components';
import { Link } from 'gatsby';
import { scrollToAnchor } from 'utils';
import { Button } from 'components';

const NavLinks = ({
  hamBurgerMenuIsOpen,
  toggleHamburgerMenu,
  makeHeaderSticky,
  viewportWidth
}) => {
  const getActiveStyle = (_makeHeaderSticky) => ({
    borderBottomColor: hamBurgerMenuIsOpen ? 'var(--dynamic-yellow)' : 'var(--dynamic-blue)',
    borderBottomStyle: 'solid',
    borderBottomWidth: hamBurgerMenuIsOpen ? '0.17em' : '0.1725em',
    color: '#fff',
    position: 'relative',
    textDecoration: 'none',
    transition: 'color 0.2s',
    lineHeight: '1.3em',
    top: _makeHeaderSticky ? '0' : '-0.2em'
  });

  return (
    <>
      {viewportWidth >= 1300 && (
        <NavLink
          to="/"
          onClick={(e) => {
            if (hamBurgerMenuIsOpen) {
              toggleHamburgerMenu(e, hamBurgerMenuIsOpen, '/');
            }
          }}
          activeStyle={getActiveStyle(makeHeaderSticky)}
          makeHeaderSticky={makeHeaderSticky}
          hamBurgerMenuIsOpen={hamBurgerMenuIsOpen}>
          Home
        </NavLink>
      )}
      <NavLink
        to="/team-building"
        onClick={(e) => {
          if (hamBurgerMenuIsOpen) {
            toggleHamburgerMenu(e, hamBurgerMenuIsOpen, '/team-building');
          }
        }}
        activeStyle={getActiveStyle(makeHeaderSticky)}
        makeHeaderSticky={makeHeaderSticky}
        hamBurgerMenuIsOpen={hamBurgerMenuIsOpen}>
        Team Building
      </NavLink>
      <NavLink
        to="/team-training"
        onClick={(e) => {
          if (hamBurgerMenuIsOpen) {
            toggleHamburgerMenu(e, hamBurgerMenuIsOpen, '/team-training');
          }
        }}
        activeStyle={getActiveStyle(makeHeaderSticky)}
        makeHeaderSticky={makeHeaderSticky}
        hamBurgerMenuIsOpen={hamBurgerMenuIsOpen}>
        Team Training
      </NavLink>
      <NavLink
        to="/event-management"
        onClick={(e) => {
          if (hamBurgerMenuIsOpen) {
            toggleHamburgerMenu(e, hamBurgerMenuIsOpen, '/event-management');
          }
        }}
        activeStyle={getActiveStyle(makeHeaderSticky)}
        makeHeaderSticky={makeHeaderSticky}
        hamBurgerMenuIsOpen={hamBurgerMenuIsOpen}>
        Event Management
      </NavLink>
      <NavLink
        to="/about-us"
        onClick={(e) => {
          if (hamBurgerMenuIsOpen) {
            toggleHamburgerMenu(e, hamBurgerMenuIsOpen, '/about-us');
          }
        }}
        activeStyle={getActiveStyle(makeHeaderSticky)}
        makeHeaderSticky={makeHeaderSticky}
        hamBurgerMenuIsOpen={hamBurgerMenuIsOpen}>
        About Us
      </NavLink>
      <NavLink
        to="/our-work"
        onClick={(e) => {
          if (hamBurgerMenuIsOpen) {
            toggleHamburgerMenu(e, hamBurgerMenuIsOpen, '/our-work');
          }
        }}
        activeStyle={getActiveStyle(makeHeaderSticky)}
        makeHeaderSticky={makeHeaderSticky}
        hamBurgerMenuIsOpen={hamBurgerMenuIsOpen}>
        Our Work
      </NavLink>
      <NavLink
        to="/blog"
        onClick={(e) => {
          if (hamBurgerMenuIsOpen) {
            toggleHamburgerMenu(e, hamBurgerMenuIsOpen, '/blog');
          }
        }}
        activeStyle={getActiveStyle(makeHeaderSticky)}
        makeHeaderSticky={makeHeaderSticky}
        hamBurgerMenuIsOpen={hamBurgerMenuIsOpen}>
        Blog
      </NavLink>
      <div
        onClick={(e) => {
          if (hamBurgerMenuIsOpen) {
            toggleHamburgerMenu(e, hamBurgerMenuIsOpen, '#contact-us');
          }
          scrollToAnchor('contact-us', {
            offset:
              viewportWidth < 360 ? -60 : viewportWidth < 375 ? -66 : viewportWidth < 967 ? -72 : -91
          });
        }}
        style={{
          position: 'relative',
          bottom: makeHeaderSticky ? '5px' : '7.5px'
        }}>
        <Button
          text="Contact Us"
          ariaLabel="contact us"
          width={viewportWidth < 1200 ? '138px ' : '120px'}
          height={viewportWidth < 1200 ? '40px' : '32px'}
          buttonColor="--dynamic-yellow"
          buttonHoverColor="--dynamic-red"
          buttonPressedColor="--dynamic-pressed-red"
        />
      </div>
    </>
  );
};

const NavLink = styled(Link)`
  font-size: 1.5rem;
  letter-spacing: 0.01em;
 font-weight: 600;
  font-family: 'proxima-nova', sans-serif;
  color: #fff;
  height: ${({ hamBurgerMenuIsOpen }) => (hamBurgerMenuIsOpen ? '1.3125em' : '1.5em')};
  text-decoration: none;
  position: relative;
  bottom: ${({ makeHeaderSticky }) => (makeHeaderSticky ? '0px' : '3px')};

  &:visited {
    color: #fff;
  }

  @media screen and (min-width: 360px) {
    font-size: 2rem;
  }

  @media screen and (min-width: 1200px) {
    font-size: 1rem;
  }
`;

export default sizes(({ width }) => ({ viewportWidth: width && width }))(NavLinks);
