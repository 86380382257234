import * as React from 'react';
function SvgLocation(props) {
  return (
    <svg width={64} height={64} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M12.64 55.84h38.72M44.8 20.96C44.8 28 32 49.76 32 49.76S19.2 28 19.2 20.96c0-7.04 5.76-12.8 12.8-12.8 7.04 0 12.8 5.76 12.8 12.8z"
        stroke="#fff"
        strokeWidth={2}
        strokeMiterlimit={10}
      />
      <path
        d="M32 25.76a4.8 4.8 0 100-9.6 4.8 4.8 0 000 9.6z"
        stroke="#fff"
        strokeWidth={2}
        strokeMiterlimit={10}
      />
    </svg>
  );
}
export default SvgLocation;
