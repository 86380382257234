import React from 'react';
import styled from 'styled-components';
import Modal from 'react-modal';
import CloseModalWhite from 'images/shared/misc/close_modal_white.svg';
import QuotationMarksSVG from 'images/shared/misc/quotation_marks.svg';

const customModalStyles = (viewportWidth) => {
  return {
    overlay: {
      zIndex: viewportWidth < 780 ? 10000000 : 6
    },
    content: {
      border: 'none',
      borderRadius: '10px',
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      padding: 0,
      overflow: 'hidden',
      marginTop: viewportWidth <= 375 ? '50px' : 0
    }
  };
};

const ReadMoreClientTestimonialsPopUp = ({ isOpen, closeModal, viewportWidth, quote }) => {
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={closeModal}
      style={customModalStyles(viewportWidth)}
      contentLabel="Modal">
      <CloseModalWrapper role="button" onClick={closeModal}>
        <CloseModal src={CloseModalWhite} alt="close modal" />
      </CloseModalWrapper>
      <TestimonialBody>
        <QuotationMarks src={QuotationMarksSVG} alt="quotation marks" />
        <Quote>{quote}</Quote>
        <QuotationMarksReverse src={QuotationMarksSVG} alt="quotation marks" />
      </TestimonialBody>
    </Modal>
  );
};

const CloseModalWrapper = styled.div`
  background-color: var(--dynamic-blue);
  border-radius: 50%;
  position: absolute;
  top: 10px;
  right: 10px;
  height: 24px;
  width: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.5rem;
  z-index: 1;
  cursor: pointer;
`;

const CloseModal = styled.img`
  height: 100%;
  width: 100%;
  z-index: 2;
  position: relative;
`;

const TestimonialBody = styled.div`
  background-color: white;
  display: flex;
  flex-direction: column;
  padding: 2em 2.188em 3em 2.188em;
  height: 100%;
  font-style: italic;
  min-height: 243px;
  border-radius: 0px 0px 10px 10px;
`;

const QuotationMarks = styled.img`
  margin-bottom: 15px;
  align-self: flex-start;
`;

const QuotationMarksReverse = styled.img`
  transform: rotate(180deg);
  align-self: flex-end;
`;

const Quote = styled.p`
  color: var(--dynamic-dark-grey);
  margin-left: 1.36em;
  line-height: 1.25em;
  font-family: 'proxima-nova', sans-serif;
`;

export default ReadMoreClientTestimonialsPopUp;
